.navbar {

    background: rgb(232, 239, 255);   
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    width: 100%;
    position: sticky; 
    top: 0;
    z-index: 999; 
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
    font-style: italic;
}

.totalnav {

    display: relative;
    width: 100%;
    
}

.navbar-container {
    display: flex;
    justify-content: space-between;
    height: 65px;
    z-index: 1;
    width: 100%;
    margin: 0 auto;
    padding: 0 auto;
} 

.loginsect {
    background-color: rgb(232, 239, 255);   
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 80px;
}

.login-link {
    text-decoration: none;
    color: #fafafa;
    transition: all 0.3s ease;
    margin-left: 20px;
}

.image-container {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    overflow: hidden;
    transition: all 0.3s ease;
}

.image-container img {
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.image-container:hover {
    height: 60px;
    width: 60px;
}

.login-link:hover {
    color: #969E7D;
}

.knownuserstyle {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.toggle-button {
    background-color: rgb(232, 239, 255);  
    color: #ffffff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.toggle-button:hover {
    background-color: #303F9F;
}

.loginformcontainer {
    width: 400px;
    max-width: 400px;
    margin: 1rem;
    padding: 2rem;
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.2);
    background: #ffffff;
}

.loginformcontainer,
.form__input,
.form__button {
    font: 500 1rem, sans-serif;
}

.form--hidden {
    display: none;
}

.form > *:first-child {
    margin-top: 0;
}

.form > *:last-child {
    margin-bottom: 0;
}

.form__title {
    margin-bottom: 2rem;
    text-align: center;
}

.form__message {
    text-align: center;
    margin-bottom: 1rem;
}

.form__message--success {
    color: #6c893b;
}

.form__message--error {
    color: rgb(137, 37, 37);
}

.form__input-group {
    margin-bottom: 1rem;
}

.form__input {
    display: block;
    width: 100%;
    padding: 0.75rem;
    box-sizing: border-box;
    border: 1px solid #dddddd;
    outline: none;
    background: #eeeeee;
    transition: background 0.2s, border-color 0.2s;
}

.form__input:focus {
    background: #ffffff;
}

.form__input--error {
    color: rgb(214, 75, 75);
    border-color: rgb(214, 75, 75);
}

.form__input-error-message {
    margin-top: 0.5rem;
    font-size: 0.85rem;
    color: rgb(214, 75, 75);
}

.form__button {
    width: 100%;
    padding: 1rem 2rem;
    font-weight: bold;
    font-size: 1.1rem;
    color: #ffffff;
    border: none;
    border-radius: #3b4e89;
    outline: none;
    cursor: pointer;
    background: #733b89;
    transition: all 0.3s ease;
    margin-bottom: 10px;
}

.form__button:hover {
    background: #8cbeff;
}

.form__button:active {
    transform: scale(0.98);
}

.form__text {
    text-align: center;
}

.form__link {
    text-decoration: none;
    cursor: pointer;
}

.form__link:hover {
    text-decoration: underline;
}

.login-container {
    
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 998;
    position: absolute;
    top: calc(50% + 70px);;
    left: 50%;
    transform: translate(-50%, -50%);

    
}

#navbar-logo {

    color: #ffe88c;  
    display: flex;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
    padding-right: 80px;
    
    font-size: 75px;
    
    margin: -32px 0 0 150px;

}  



#navbar-logo:hover {
    animation: shake 0.5s;
}

@keyframes shake {

    0% { transform: translate(1px, 1px) rotate(-1deg); }
    10% { transform: translate(-1px, -2px) rotate(-1deg); }
    20% { transform: translate(-3px, 0px) rotate(1deg); }
    30% { transform: translate(3px, 2px) rotate(0deg); }
    40% { transform: translate(1px, -1px) rotate(1deg); }
    50% { transform: translate(-1px, 2px) rotate(-1deg); }
    60% { transform: translate(-3px, 1px) rotate(0deg); }
    70% { transform: translate(3px, 1px) rotate(-1deg); }
    80% { transform: translate(-1px, -1px) rotate(1deg); }
    90% { transform: translate(1px, 2px) rotate(0deg); }
    100% { transform: translate(1px, -2px) rotate(-1deg); }

}

.navbar-menu {

    display: flex;
    align-items: center;
    gap: 40px;
    list-style: none;
    text-align: center;
    padding-left: 100px;
    margin-right: 80px;

}

.navbar-item {
    font-size: 50px;
}

.navbar-link-container a {

    text-decoration: none;
    color: #8ccbff;
    transition: all 0.3s ease;
    margin-left: 20px;

}

.navbar-link-container a:hover {


    color: #e1e9eb;
    border: 4px solid #8ccbff;
    border-radius: 10px;


}

#homelink a:hover {
    padding: 12px 35px 12px 35px;
}

#reslink a:hover {  
    padding: 12px;
}

#desclink a:hover {
    padding: 12px 20px 12px 20px;
}

.popup-menu {

    display: none;
    position: absolute;
    background-color: #969E7D;
    width: 240px;
    overflow: scroll;
    padding: 10px;
    border-radius: 2px; 
    list-style: none;
    text-decoration: none;
    z-index: 999;


}


/* .popup-menu-container {

    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
    gap: 30px;
    padding-top: 35px;
    
} */

.navbar-link a {

    color: #fafaf8;
    text-decoration: none;
    transition: all 0.3s ease;
    border-radius: 3px;
    width: 50px;

}

.navbar-link:hover {

    color: #919191;
    background: #5D4037;

    border-radius: 3px;


    transition: all 0.3s ease;
    
}

#popup-nav-mobile {
    display: none;
}