/* Add your custom styles here */
form {
    max-width: 600px;
    margin: 0 auto;
}

label {
    display: block;
    margin-bottom: 10px;
}

h1 {
    margin-top: 50px; 
    margin-bottom: 10px;
}
/* TODO: Pad the message box wider */
input,
textarea {
    width: 100%;
    padding: 10px; 
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
}


button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
}
