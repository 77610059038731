
.service-button {
  width: 50%;
  font-size: 30px; /* Increase font size for bigger text */
  padding: 40px 30px; /* Increase padding to make the button bigger */
  margin: 10px; /* Adds space between buttons */
  min-width: 500px; /* Sets a minimum width for each button */
  min-height: 50px; /* Sets a minimum height for each button */
  cursor: pointer; /* Changes cursor to pointer on hover for better UX */
  border: none; /* Optional: Removes border */
  background-color: black; /* Optional: Adds a background color */
  border-radius: 5px; /* Optional: Rounds the corners */
  text-align: center;
  font-style: italic;
}

  
.service-button:hover {
  background-color: rgb(215, 223, 235); /* Darker blue color on hover */
}

.service-buttons-container {
  display: flex;
  flex-direction: column; /* Keeps buttons stacked vertically */
  align-items: center; /* Centers buttons within the container */
  justify-content: start; /* Aligns content to the top of the container */
  margin-left: auto; /* Pushes the container to the right */
  margin-right: -500px;
  width: 50%; /* Adjusts the width of the container */
  height: 100%; /* Optional: Adjust based on your design needs */
}

