.product-buttons-container {
    text-align: center; /* Center all content */
    padding: 20px; /* Add padding for spacing */
}

.product-buttons-container h2 {
    margin-bottom: 20px; /* Space below the header */
}

.product-images {
    display: flex;
    justify-content: center; /* Center the images horizontally */
    gap: 20px; /* Space between images */
}

h2 {
    width: 50%;  
    margin: 0 auto; 
    padding: 10px 0;  
    text-align: center;  
}

.product-pic {
    width: 300px; /* Fixed width for each image */
    height: 300px; /* Fixed height to make them circles */
    border-radius: 0; /* Makes the images circular */
    object-fit: contain; /* Ensures images cover the circle area without being distorted */
}

.product-pic:hover {
    transform: scale(1.1); /* Adjust the scale value to control the zoom level */
}

.product-buttons-container button {
    text-align: center;
    margin-top: 20px;
    background-color: black; /* Choose a color that fits your design */
    color: white;
    border: none; /* Typically, no border looks better for modern design */
    padding: 30px 60px; /* Increase padding to make the button bigger */
    font-size: 40px; /* Larger font size for better readability */
    cursor: pointer; /* Cursor indicates the element is clickable */
    border-radius: 5px; /* Rounded corners */
    transition: background-color 0.3s ease; /* Smooth transition for hover effect */
    box-shadow: 0 4px 8px rgba(0,0,0,0.1); /* Adds subtle shadow for depth */
}


.product-buttons-container button:hover {
    background-color: grey; /* Slightly darker shade on hover for better UX */
}