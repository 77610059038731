/* TODO: responsive CSS rules with media queries */

.homepage-container {
    display: flex;
    flex-direction: column; /* Stacks content vertically */
    height: 100vh; /* Optional: Adjust based on your design needs */
}

h1 {
    width: 100%; /* Ensures the banner spans the full width */
    text-align: center; /* Centers the text in the banner */
    margin-top: 0; /* Adjust as needed */
    margin-bottom: 0; /* Adds padding above and below the text */
    background-color: rgb(232, 239, 255); /* Optional: Adds background color to the banner */
    font-weight: 700; /* Optional: for a bolder text */
    font-size: 100px;
}

h2 {
    width: 100%; /* Ensures the banner spans the full width */
    text-align: center; /* Centers the text in the banner */
    margin-top: 0; /* Adjust as needed */
    padding-bottom: 20px;
    background-color: rgb(232, 239, 255); /* Optional: Adds background color to the banner */
    font-weight: 700; /* Optional: for a bolder text */
    font-size: 40px;

}

.italic-text {
    font-style: italic; /* Makes the text italic */
}


/* If you need the products pane to take a specific amount of space */
.product-buttons-container {
    flex: 1; /* Adjust this value based on your layout needs */
}

.service-buttons-container {
    flex: 1; /* Ensure both sides are balanced or adjust according to your design */
}